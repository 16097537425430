import React, {useEffect} from "react";
import PropTypes from "prop-types";

function FacebookShareButton(props) {

    useEffect(() => {
        facebook()
    }, [])

    return <div className="fb-share-button"
                data-href={props.url}
                data-layout="button"
                data-size="small">
        <a target="_blank"
           href={`https://www.facebook.com/sharer/sharer.php?u=${
               encodeURIComponent(props.url)
           }&amp;src=sdkpreparse`}
           className="fb-xfbml-parse-ignore">
            Share
        </a>
    </div>
}
FacebookShareButton.propTypes = {
    url: PropTypes.string.isRequired,
}

function facebook() {
    let js, fjs = document.getElementsByTagName("script")[0];
    if (document.getElementById("facebook-jssdk")) return;
    js = document.createElement("script");
    js.id = "facebook-jssdk";
    js.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0";
    fjs.parentNode.insertBefore(js, fjs);
}

export default FacebookShareButton;
