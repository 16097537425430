import React from 'react';
import './App.css';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import ShirtList from "./ShirtList";
import Header from "./Header"
import BlackLives from "./BlackLives";
import AboutUs from "./AboutUs";
import BlogList from "./BlogList";
import BlogPost from "./BlogPost";
import PinAllButton from "./PinAllButton";

function App() {
  return (
    <div className="App">
        <Router>
            <BlackLives/>
            <Header/>
            <Switch>
                <Route path="/about" component={AboutUs}/>
                <Route path="/blog/:slug" component={BlogPost}/>
                <Route path="/blog" component={BlogList}/>
                <Route path="/" component={ShirtList}/>
            </Switch>
        </Router>
    </div>
  );
}

export default App;
