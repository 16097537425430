import React, {useEffect} from "react";
import "./AboutUs.css"
import analytics from "./analytics";
import LinkOut from "./LinkOut";
import {Helmet} from "react-helmet";

function AboutUs() {
    const civilWarLink = "https://www.nytimes.com/2017/05/01/us/politics/trump-andrew-jackson-fact-check.html";
    const pepperBalls = "https://www.washingtonpost.com/lifestyle/media/tear-gas-or-pepper-balls-in-response-to-assault-on-white-house-protesters-trump-harps-on-the-semantics/2020/06/03/b93a5320-a5b6-11ea-bb20-ebf0921f3bbd_story.html";
    const obama = "https://twitter.com/realDonaldTrump/status/232572505238433794";
    const oompaLoompa = "https://politicalpunchline.com/trump-orange-face/";
    const colbert = "https://www.cbs.com/shows/the-late-show-with-stephen-colbert/";

    useEffect(() => {
        analytics().logEvent(
            'page_view',
            {
                page_title: "about_us",
                page_path: window.location.path,
            }
        )
    }, [])

    return <div className="AboutUs">
        <Helmet>
            <title>About Us | Hilarious Trump Shirts</title>
            <meta
                name="description"
                content={
                    "Trump is making a mockery of the US Presidency. " +
                    "But at least what he says & does is comic gold! Our " +
                    "hilarious Trump shirts help you laugh the pain away."
                }
            />
            <link
                rel="canonical"
                href="https://hilarioustrumpshirts.com/about"
            />
        </Helmet>
        <div className="text">
            <h3>Laughing the pain away ... one hilarious anti Donald Trump shirt at a time.</h3>
            <p>
                Not a day goes by that Donald Trump isn't making cringe-worthy
                headlines for one thing or another 🤨 Whether it's{" "}
                {<LinkOut url={civilWarLink} text="his revisionist sense of history"/>},{" "}
                {<LinkOut url={pepperBalls} text="his complete disregard for the basic rights of American citizens"/>},{" "}
                {<LinkOut url={obama} text="his Tweets peddling fake news as fact"/>}, or{" "}
                {<LinkOut url={oompaLoompa} text="his orange, oompa loompa spray tan glow"/>},{" "}
                Trump has made himself the poster child of all that is wrong with America.
            </p>
            <p>
                Still, we have to ask ourselves the question: Is there a silver
                lining to Trump's shenanigans? We think—or at least hope—the
                answer is yes 😃 His body of farcical work since 2016 (and earlier)
                has proven a fertile ground for comedians the world over. While we
                all love to laugh at{" "}
                {<LinkOut url={colbert} text="the nightly Colbert punchlines dropped at Trump's expense"/>},
                there's another, more personally expressive comedic art form
                that is starting to take hold: the Hilarious Trump Shirt 😆👕
            </p>
            <p>
                If you've had any luck recently, you may have already
                laid eyes upon a passerby donning screen print
                comedy gold inspired by the misadventures of Donald Trump. But
                if not, we're sure glad you've stumbled across this website full
                of funny anti Donald trump shirts! We
                pledge to bring you the most laugh-out-loud worthy shirts
                inspired by the misdeeds of "the Donald". Our sincere wish is
                that, despite the embarrassment 🤦‍♀️ that Donald Trump causes the United
                States on a daily basis, we can all get through it
                knowing there are Hilarious Trump Shirts out there bringing
                little moments of joy to us all.
            </p>
            <p>
                If you do happen to find yourself bent over laughing at any of
                the shirts on this website, please consider sharing those
                endorphins with your friends and family 👪 To make it as easy as
                possible, we've provided convenient purchase links for each shirt.
                Who knows, maybe your Hilarious Trump Shirt will brighten
                someone's day after the news of yet another Trumpian episode
                has broken?
            </p>
            <h4>A note about our purchase links</h4>
            <p>
                Our shirts come from all over the Internet. Some come from
                Amazon.com and as an Amazon Associate we earn an affiliate
                fee from qualifying purchases.
            </p>
        </div>
    </div>
}

export default AboutUs;
