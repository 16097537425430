import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import "./ShirtItem.css";
import api from "./api";
import BuyButton from "./BuyButton";
import {Link} from "react-router-dom";

function ShirtItem(props) {

    const [blogSlug, setBlogSlug] = useState(null);

    useEffect(() => {
        if (props.withBlogLink && props.shirt.blogId) {
            api().doc(`blogs/${props.shirt.blogId}`)
                .get()
                .then(blog => {
                    setBlogSlug(blog.get("slug"));
                })
                .catch(error => {
                    console.error(`Error getting blog: ${error.message}`);
                })
        }
    }, [])

    function getDesc() {
        return props.blogDesc ? props.blogDesc : props.shirt.description;
    }

    return (
        <div className="ShirtItem">
            {props.shirt.blurb && (
                <p>
                    {props.shirt.blurb}
                </p>
            )}
            {props.pinId && (
                <img data-pin-id={props.pinId} data-pin-description={getDesc()} src={props.shirt.image}/>
            )}
            {!props.pinId && (
                <img data-pin-description={getDesc()} src={props.shirt.image}/>
            )}
            <p className="description">
                {props.shirt.description}
            </p>
            {blogSlug && (
                <Link to={`blog/${blogSlug}`}>
                    Read the blog
                </Link>
            )}
            <BuyButton shirt={props.shirt}/>
        </div>
    )
}
ShirtItem.propTypes = {
    shirt: PropTypes.object.isRequired,
    withBlogLink: PropTypes.bool,
    blogDesc: PropTypes.string,
    pinId: PropTypes.string,
}

export default ShirtItem;
