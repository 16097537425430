function twitter() {
    let js, fjs = document.getElementsByTagName("script")[0],
        t = window.twttr || {};
    if (document.getElementById("twitter-wjs")) return t;
    js = document.createElement("script");
    js.id = "twitter-wjs";
    js.src = "https://platform.twitter.com/widgets.js";
    fjs.parentNode.insertBefore(js, fjs);

    t._e = [];
    t.ready = function(f) {
        t._e.push(f);
    };

    window.twttr = t;

    return t;
}

export default twitter;
