import React from "react";
import "./BlackLives.css";
import LinkOut from "./LinkOut";

function BlackLives() {
    return (
        <div className="BlackLives">
            <LinkOut
                url="https://nymag.com/strategist/article/where-to-donate-for-black-lives-matter.html"
                text="Black Lives Matter ✊🏾"/>
        </div>
    )
}

export default BlackLives;