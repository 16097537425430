import React, {useEffect, useState} from "react";
import api from "./api";
import LoadingSpinner from "./LoadingSpinner";
import ErrorMessage from "./ErrorMessage";
import {Link} from "react-router-dom";
import analytics from "./analytics";
import "./BlogList.css"
import {Helmet} from "react-helmet";

function BlogList() {

    const [blogs, setBlogs] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        api().collection("blogs")
            .orderBy("createdTimestamp", "desc")
            .get()
            .then(blogs => {
                if (blogs.empty) {
                    setBlogs([]);
                } else {
                    setBlogs(blogs.docs.map(doc => {
                        const blog = doc.data();
                        blog.blogId = doc.id;
                        return blog;
                    }))
                }
            })
            .catch(error => {
                setError(error);
            })
    }, [])

    useEffect(() => {
        analytics().logEvent(
            "page_view",
            {
                page_title: "blog_list",
                page_path: window.location.path,
            }
        )
    }, [])

    function getBlogEntry(blog) {
        return <div className="entry" key={blog.blogId}>
            <div className="date">
                {blog.createdTimestamp.toDate().toLocaleDateString()}
            </div>
            <Link to={`/blog/${blog.slug}`}>
                {blog.title}
            </Link>
        </div>
    }

    return (
        <div className="BlogList">
            <Helmet>
                <title>
                    The stories behind the funny Trump shirts | Hilarious Trump Shirts
                </title>
                <meta
                    name="description"
                    content={
                        "Behind every hilarious shirt is something Trump " +
                        "has said, done, or Tweeted. These blog posts " +
                        "describe the misdeeds behind each funny shirt."
                    }
                />
                <link
                    rel="canonical"
                    href="https://hilarioustrumpshirts.com/blog"
                />
            </Helmet>
            {!blogs && !error && (
                <LoadingSpinner/>
            )}
            {error && (
                <ErrorMessage message={
                    "Oh, Trump ... 🙄 We've encountered an error loading " +
                        "the blogs! Please make sure you're connected to " +
                        "the Internet and try again."
                }/>
            )}
            {blogs && blogs.length && (
                <div className="links">
                    {blogs.map(blog => getBlogEntry(blog))}
                </div>
            )}
            {blogs && !blogs.length && (
                <div>Coming soon</div>
            )}
        </div>
    )
}

export default BlogList;
