import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import api from "./api";
import ErrorMessage from "./ErrorMessage";
import analytics from "./analytics";
import LoadingSpinner from "./LoadingSpinner";
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2
} from 'react-html-parser';
import "./BlogPost.css";
import ShirtItem from "./ShirtItem";
import twitter from "./twitter";
import FacebookShareButton from "./FacebookShareButton";
import TweetButton from "./TweetButton";
import RedditPostButton from "./RedditPostButton";
import PinAllButton from "./PinAllButton.js";


const ERROR_LOADING = "error_loading";
const ERROR_NO_POST = "error_no_post";

function BlogPost() {
    const {slug} = useParams();
    const [post, setPost] = useState(null);
    const [nextPost, setNextPost] = useState(null);
    const [shirt, setShirt] = useState(null);
    const [recentBlogs, setRecentBlogs] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        api().collection("blogs")
            .where("slug", "==", slug)
            .get()
            .then(querySnap => {
                if (querySnap.empty) {
                    setError(ERROR_NO_POST);
                } else {
                    setPost(querySnap.docs[0].data());
                }
            })
            .catch(() => {
                setError(ERROR_LOADING);
            })
    }, [slug])

    useEffect(() => {
        if (post) {
            api().doc(`shirts/${post.shirtId}`)
                .get()
                .then(shirt => {
                    setShirt(shirt.data());
                })
                .catch(error => {
                    console.error(`Error getting shirt ${error.message}`);
                })
        }
    }, [post])

    useEffect(() => {
        api().collection("blogs")
            .orderBy("createdTimestamp", "desc")
            .limit(10)
            .get()
            .then(querySnap => {
                if (querySnap.empty) {
                    setRecentBlogs([]);
                } else {
                    setRecentBlogs(querySnap.docs.map(doc => doc.data()));
                }
            })
            .catch(error => {
                console.error(`Error getting recent blogs ${error.message}`)
            })
    }, [])

    useEffect(() => {
        if (post) {
            api().collection("blogs")
                .where("createdTimestamp", ">", post.createdTimestamp)
                .orderBy("createdTimestamp", "asc")
                .limit(1)
                .get()
                .then(querySnap => {
                    if (!querySnap.empty) {
                        setNextPost(querySnap.docs[0].data());
                    }
                })
                .catch(error => {
                    console.error(`Error loading next post: ${error.message}`);
                })
        }
    }, [post])

    useEffect(() => {
        if (post) {
            twitter().widgets.load();
        }
    }, [post])

    useEffect(() => {
        analytics().logEvent(
            "page_view",
            {
                page_title: "blog_post",
                page_path: window.location.path,
            }
        )
    }, [])

    function getRecentBlogLinks() {
        return recentBlogs.map(blog => {
            return <Link
                key={blog.slug}
                to={`${blog.slug}`}>
                {blog.title}
            </Link>
        });
    }

    function getTitle() {
        let newTitle;
        if (post.title) {
            newTitle = post.title +" | Hilarious Trump Shirts"
        } else {
            newTitle = "Blog Page | Hilarious Trump Shirts"
        }
        return newTitle;
    }

    function getDesc() {
        let newDesc;
        if (post.blurb) {
            newDesc = post.blurb
        } else {
            const noHtml = post.content.replace(/<[^>]*>/g, "");
            const first160Chars = noHtml.substring(0, 160);
            const lastSpaceIndex = first160Chars.lastIndexOf(" ");
            newDesc = first160Chars.substring(0, lastSpaceIndex);
        }
        return newDesc;
    }

    return <div className="BlogPost">
        {!error && !post && (
            <LoadingSpinner/>
        )}
        {error && error === ERROR_LOADING && (
            <ErrorMessage message={
                "Trump me ... 😒 There was an error loading the page! " +
                    "Please confirm you've got an Internet connection and " +
                    "try again."
            }/>
        )}
        {error && error === ERROR_NO_POST && (
            <ErrorMessage message={
                "Well that's just Trumptastic ... 🤦 It doesn't look like " +
                    "there's a blog here. Please go back to the blogs page " +
                    "and try clicking the link again."
            }/>
        )}
        {!error && post && (
            <div className="content-container">
                <Helmet>
                    <meta
                        name="description"
                        content={getDesc()}
                    />
                    <meta
                        property="og:url"
                        content={window.location.href}
                    />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={getTitle()} />
                    { shirt && (
                        <meta
                            property="og:image:secure"
                            content={shirt.image}
                        />
                    )}
                    <title>{getTitle()}</title>
                    <link
                        rel="canonical"
                        href={
                            `https://hilarioustrumpshirts.com/blog/${
                                post.slug
                            }`
                        }
                    />
                </Helmet>
                <h1>{post.title}</h1>
                <div className="columns">
                    <div className="social-buttons">
                        <FacebookShareButton url={window.location.href}/>
                        <TweetButton/>
                        <RedditPostButton
                            url={window.location.href}
                            title={post.title}
                        />
                    </div>
                    <div className="blog">
                        {shirt && (
                            <ShirtItem shirt={shirt} blogDesc={post.title} pinId={post.pinId} />
                        )}
                        <div className="content">
                            {ReactHtmlParser(post.content)}
                        </div>
                        {nextPost && (
                            <div className="next-post">
                                Next up:{" "}
                                <Link to={`/blog/${nextPost.slug}`}>
                                    {nextPost.title}
                                </Link>
                            </div>
                        )}
                    </div>
                    {recentBlogs && recentBlogs.length && (
                        <div className="recent-blogs">
                            <h3>Recent blogs</h3>
                            {getRecentBlogLinks()}
                        </div>
                    )}
                </div>
                { shirt && (
                        <PinAllButton
                    />
                )}
            </div>
        )}
    </div>
}

export default BlogPost;
