import PropTypes from "prop-types";
import React from "react";
import analytics from "./analytics";
import "./BuyButton.css";

function BuyButton(props) {

    function openLink() {
        analytics().logEvent(
            'buy_on_amazon_clicked',
            {
                link: props.shirt.affiliatelink,
            }
        )
        window.open(props.shirt.affiliatelink, '_blank');
    }

    return (
        <button className="BuyButton" onClick={() => openLink()}>
            Buy on {props.shirt.storeName}
        </button>
    )
}
BuyButton.propTypes = {
    shirt: PropTypes.object.isRequired,
};

export default BuyButton;
