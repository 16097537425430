import React from "react";
import PropTypes from "prop-types";

function LinkOut(props) {
    return <a href={props.url} target="_blank" rel="noopener noreferrer">
        {props.text}
    </a>
}
LinkOut.propTypes = {
    url: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};

export default LinkOut;
