import React from "react";
import "./Header.css";
import {Link} from "react-router-dom";

function Header() {
    return (
            <div className="Header">
                <div className="logo">
                    <img src="/icon-192.png" alt="Hilarious Trump shirts logo" data-pin-nopin="true"/>
                    <span className="title">
                        Hilarious Trump Shirts
                    </span>
                </div>
                <div className="menu">
                    <Link to="/">
                        Shirts
                    </Link>
                    <Link to="/blog">
                        Blog
                    </Link>
                    <Link to="/about">
                        About Us
                    </Link>
                </div>
            </div>
    )
}

export default Header;
