import React, {useEffect, useState} from "react";
import api from "./api";
import ShirtItem from "./ShirtItem";
import LoadingSpinner from "./LoadingSpinner";
import analytics from "./analytics";
import "./ShirtList.css";
import {Helmet} from "react-helmet";
import PinAllButton from "./PinAllButton";

function ShirtList() {

    const [shirts, setShirts] = useState(null);

    useEffect(() => {
        api().collection("shirts")
            .orderBy("sortOrder", "asc")
            .get()
            .then(querySnap => {
                if (querySnap.empty) {
                    setShirts([]);
                } else {
                    setShirts(querySnap.docs.map(doc => {
                        const shirt = doc.data();
                        shirt['shirtId'] = doc.id;
                        return shirt;
                    }));
                }
            })
            .catch(error => {
                console.error(`Error loading shirts: ${error}`);
            })
    }, [])

    useEffect(() => {
        analytics().logEvent(
            'page_view',
            {
                page_title: "shirt_list",
                page_path: window.location.path,
            }
        )
    }, [])

    function getShirtItems() {
        return shirts.map(shirt =>
            <ShirtItem key={shirt.shirtId} shirt={shirt} withBlogLink={true} pinId={shirt.pinId}/>)
    }

    return (
        <div className="ShirtList">
            <Helmet>
                <title>
                    Curated list of funny Trump shirts | Hilarious Trump Shirts
                </title>
                <meta
                    name="description"
                    content={
                        "Check out these curated shirts commemorating " +
                        "the hilarious misdeeds of Donald Trump. While " +
                        "terrible, we can at least enjoy a laugh or two at " +
                        "his expense."
                    }
                />
                <link
                    rel="canonical"
                    href="https://hilarioustrumpshirts.com"
                />
            </Helmet>
            {!shirts && (
                <LoadingSpinner/>
            )}
            {shirts && getShirtItems()}
            {shirts && (
                <PinAllButton
                />
            )}
        </div>
    )
}

export default ShirtList;
