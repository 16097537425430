import React, {useEffect} from "react";
import PropTypes from "prop-types";

function PinAllButton() {

    useEffect(() => {
        pinItAll();
    }, [])

    return <a data-pin-do="buttonBookmark"
              data-pin-hover="true"
              href={`https://www.pinterest.com/pin/create/button/`}></a>

}

function pinItAll() {
    let js, fjs = document.getElementsByTagName("script")[0];
    if (document.getElementById("pinterest-jssdk")) return;
    js = document.createElement("script");
    js.id = "pinterest-jssdk";
    js.src = "//assets.pinterest.com/js/pinit.js";
    js.setAttribute("data-pin-hover",true);
    js.async = true;
    fjs.parentNode.insertBefore(js, fjs);
}

export default PinAllButton;