import * as firebase from "firebase/app";

function config() {
    // Prod
    if (window.location.hostname === "hilarioustrumpshirts.com"
        || window.location.hostname === "www.hilarioustrumpshirts.com") {
        return {
            apiKey: "AIzaSyCVd8Navp2Jb5Zg0lXXm-ULCQitlz9fPQg",
            authDomain: "hilarious-trump-shirts.firebaseapp.com",
            databaseURL: "https://hilarious-trump-shirts.firebaseio.com",
            projectId: "hilarious-trump-shirts",
            storageBucket: "hilarious-trump-shirts.appspot.com",
            messagingSenderId: "450066360255",
            appId: "1:450066360255:web:de4b5c5f12e4e49cefa30a",
            measurementId: "G-3FG75NS1TH"
        };
    }
    // Dev
    else {
        return {
            apiKey: "AIzaSyDhjjqR1Mfhm7wkNCUfI6gEO1pe_1BX2HY",
            authDomain: "hilarious-trump-shirts-dev.firebaseapp.com",
            databaseURL: "https://hilarious-trump-shirts-dev.firebaseio.com",
            projectId: "hilarious-trump-shirts-dev",
            storageBucket: "hilarious-trump-shirts-dev.appspot.com",
            messagingSenderId: "412273236045",
            appId: "1:412273236045:web:d9b76ce25eb008c5b4e404",
            measurementId: "G-JWGT9JSLNT"
        };
    }
}

function firebaseApp() {
    if (!firebase.apps.length) {
        firebase.initializeApp(config());
    }
    return firebase.app();
}

export default firebaseApp;
