import React, {useEffect} from "react";
import twitter from "./twitter";

function TweetButton() {

    useEffect(() => {
        twitter();
    }, [])

    return <a className="TweetButton twitter-share-button"
              href="https://twitter.com/intent/tweet"
              data-via="LolTrumpShirts">
        Tweet
    </a>
}

export default TweetButton;
