import React from "react";
import PropTypes from "prop-types";
import "./RedditPostButton.css"

function RedditPostButton(props) {

    function url() {
        return `https://www.reddit.com/submit?url=${
            encodeURIComponent(props.url)
        }&title=${
            encodeURIComponent(props.title)
        }`;
    }

    return <a className="RedditPostButton"
              href={url()}
              target="_blank"
              rel="noopener noreferrer">
        <img src="/reddit-icon.svg" alt="Reddit icon"/>
        <span>Post</span>
    </a>
}
RedditPostButton.propTypes = {
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default RedditPostButton;
